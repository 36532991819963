import { view } from "@risingstack/react-easy-state";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PropertyMap from '../../../components/property-map/property-map'
import GenericPageContainer from '../../../GenericPageElements/GenericPageContainer'
import request from "../../../helpers/request";
import hasPermission from '../../../helpers/permissions';
import endpoints from "../../../helpers/endpoints";
import { AddressBase, MeterAddress, CMOSLive, Summary, ValuationOfficeAndCouncilTax, CMOSFormatted, ConfirmModal, getMDSData } from '../AutomatedAddressCleanse/shared.jsx'
import { CurrentMeter, Summary as MCVSummary, syncRowHeights } from '../MeterCoordinatesValidation/shared.jsx'
import { MDS } from '../OccupierTelephoneValidation/sharedComponents'

export default view(function DataCleanseValidationQA() {
    const [summaryData, setSummaryData] = useState({})
    const [selectedMatches, setSelectedMatches] = useState({abp: '', voa: '', ct: ''})
    const [confirmModal, setConfirmModal] = useState(false)
    const [mdsData, setMdsData] = useState([])
    const [mdsDataReady, setMdsDataReady] = useState(true)
    const [mdsMeterData, setMdsMeterData] = useState([])
    const [customMDSPostcode, setCustomMDSPostcode] = useState('')
    const [cmosUserInput, setCmosUserInput] = useState({})
    const [meterUserInput, setMeterUserInput] = useState({})
    const [disabledByUserType, setDisabledByUserType] = useState(true)
    const [matchCategory, setMatchCategory] = useState("Match_Category")
    const prefix = "DATA_CLEANSE_VALIDATION"
    const [meterOrCmos, setMeterOrCmos] = useState("meter")
    const [addressType, setAddressType] = useState("");
    const [loadingPremises, setLoadingPremises] = useState(false)
    const [premisesData, setPremisesData] = useState({})
    const [selectedModule, setSelectedModule] = useState();
    
    const [translations, setTranslations] = useState({})
    useEffect(() => {
        request(true).get('/translate?key=mosl_eligibility')
        .then(e => {
            setTranslations(e.data)
        })
    }, [])
    useEffect(() => {
        setSelectedMatches({abp: '', voa: '', ct: ''})
        summaryData['UPRN_Conflict'] && summaryData['VOA_Conflict'] ? (
          summaryData['Joint_Match_Category'] = `${summaryData['UPRN_Conflict']} & ${summaryData['VOA_Conflict']}`
        ) : summaryData['UPRN_Conflict'] ? (
          summaryData['Joint_Match_Category'] = `${summaryData['UPRN_Conflict']}`
        ) : summaryData['VOA_Conflict'] ? (
          summaryData['Joint_Match_Category'] = `${summaryData['VOA_Conflict']}`
        ) : (
          summaryData['Joint_Match_Category'] = ''
        )
        if (summaryData.Module === "INVALID_UPRN_VOA" || summaryData.Module === "INVALID_UPRN_VOA_CMOS") {
          setMatchCategory("Joint_Match_Category")
        } else {
          setMatchCategory("Match_Category")
        }

        if (summaryData && summaryData.Meter_ID && summaryData.Module === "METER_COORDINATION_VALIDAITON") {
          getPremisesData()
        } else {
          setPremisesData({})
          setLoadingPremises(false)
        }
    }, [summaryData.Core_SPID])

    const getPremisesData = () => {
      setLoadingPremises(true)
      // setUserInput({})
      request(true).get(endpoints.DATA_CLEANSE_VALIDATION_GET_PREMISES, {
        doesCancel: true,
        params: {
          SPID: summaryData.Core_SPID
        }
      }).then(r => {
        setPremisesData(r.data)
        setLoadingPremises(false)
      }).catch(e => {
        console.log(e)
        setLoadingPremises(false)
      })
    }

    return (
        <GenericPageContainer
          containerWidth={"95vw"}
          title="Data Cleanse Validation - Quality Assurance"
          titleIconClass={"fak fa-id-business-icon fa-2x colour-primary"}
          titleRight={
            <div>
              <Link className="navigate" to="/portal/data-cleanse-validation">
                  To Summary
              </Link>
              <Link className="navigate" to="/portal/data-cleanse-validation/assurance">
                  To Assurance
              </Link>
            </div>
          }
        >
          <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
            <div style={{display: 'grid', gap: 10}}>
              <Summary translations={translations} summaryData={summaryData} setSummaryData={setSummaryData} workflow={true} setMdsData={setMdsData} setMdsDataReady={setMdsDataReady} getMDSData={() => getMDSData(summaryData, setMdsDataReady, prefix, customMDSPostcode, setMdsData, setMdsMeterData)} setConfirmModal={setConfirmModal} prefix={prefix} userInput={cmosUserInput} setAddressType={setAddressType} setDisabledByUserType={setDisabledByUserType} qualityAssurance={true} selectedModule={selectedModule} setSelectedModule={setSelectedModule} setMdsMeterData={setMdsMeterData} customMDSPostcode={customMDSPostcode} />
            </div>
            { ["METER_COORDINATION_VALIDAITON", "PRIORITY_CLEANSING_METER", "MANAGED_SERVICE_METER"].includes(summaryData?.Module) ?
              <>
                <div style={{display: 'grid', gridAutoColumns: '1fr', gridAutoFlow: 'column', gap: 10}}>
                  { meterOrCmos === "meter" ?
                    <CurrentMeter translations={translations} summaryData={summaryData} selectedMatches={selectedMatches} setSelectedMatches={setSelectedMatches} premisesData={premisesData} loading={loadingPremises} toggle={() => setMeterOrCmos('cmos')} />
                    :
                    <CMOSLive translations={translations} summaryData={premisesData} selectedMatches={selectedMatches} setSelectedMatches={setSelectedMatches} prefix={prefix} disabledByUserType={disabledByUserType} toggle={() => setMeterOrCmos('meter')} />
                  }
                  <AddressBase translations={translations} summaryData={summaryData} selectedMatches={selectedMatches} setSelectedMatches={setSelectedMatches} prefix={prefix} disabledByUserType={disabledByUserType} setUserInput={setCmosUserInput} />
                  <ValuationOfficeAndCouncilTax translations={translations} summaryData={summaryData} selectedMatches={selectedMatches} setSelectedMatches={setSelectedMatches} prefix={prefix} disabledByUserType={disabledByUserType} setUserInput={setCmosUserInput} />
                  <MeterAddress translations={translations} summaryData={summaryData} selectedMatches={selectedMatches} setSelectedMatches={setSelectedMatches} prefix={prefix} disabledByUserType={disabledByUserType} userInput={meterUserInput} setUserInput={setMeterUserInput} />
                  <CMOSFormatted translations={translations} summaryData={summaryData} selectedMatches={selectedMatches} prefix={prefix} disabledByUserType={disabledByUserType} userInput={cmosUserInput} setUserInput={setCmosUserInput} meterInput={meterUserInput} setSummaryData={setSummaryData} />
                </div>
              </>
            :
              <>
                <div style={{display: 'grid', gridAutoColumns: '1fr', gridAutoFlow: 'column', gap: 10}}>
                  <CMOSLive translations={translations} summaryData={summaryData} selectedMatches={selectedMatches} setSelectedMatches={setSelectedMatches} prefix={prefix} disabledByUserType={disabledByUserType} />
                  <AddressBase translations={translations} summaryData={summaryData} selectedMatches={selectedMatches} setSelectedMatches={setSelectedMatches} prefix={prefix} disabledByUserType={disabledByUserType} setUserInput={setCmosUserInput} />
                  <ValuationOfficeAndCouncilTax translations={translations} summaryData={summaryData} selectedMatches={selectedMatches} setSelectedMatches={setSelectedMatches} prefix={prefix} disabledByUserType={disabledByUserType} setUserInput={setCmosUserInput} />
                  <MeterAddress translations={translations} summaryData={summaryData} selectedMatches={selectedMatches} setSelectedMatches={setSelectedMatches} prefix={prefix} disabledByUserType={disabledByUserType} userInput={meterUserInput} setUserInput={setMeterUserInput} />
                  <CMOSFormatted translations={translations} summaryData={summaryData} setSummaryData={setSummaryData} workflow={true} selectedMatches={selectedMatches} prefix={prefix} disabledByUserType={disabledByUserType} userInput={cmosUserInput} setUserInput={setCmosUserInput} meterInput={meterUserInput} />
                </div>
                <div>
                  <ConfirmModal confirmModal={confirmModal} setConfirmModal={setConfirmModal} prefix={prefix} summaryData={summaryData} setSummaryData={setSummaryData} cmosUserInput={cmosUserInput} meterUserInput={meterUserInput} />
                </div>
                <MDS data={ mdsData } MDSDataReady={ mdsDataReady } getData={ () => getMDSData(summaryData, setMdsDataReady, prefix, customMDSPostcode, setMdsData, setMdsMeterData) } customPostcode={ customMDSPostcode } setCustomPostcode={ setCustomMDSPostcode } style={{ fontSize: '14px' }} classes={"squish"} SPID={summaryData?.Core_SPID} /> 
              <div style={{minHeight: '40vh', marginBottom: '10em'}}>
                <PropertyMap 
                  translations={translations} 
                  eastingNorthing={summaryData && summaryData.CMOS_UPRN_ABP_GISX && summaryData.CMOS_UPRN_ABP_GISY && [parseInt(summaryData.CMOS_UPRN_ABP_GISX), parseInt(summaryData.CMOS_UPRN_ABP_GISY)]} 
                  address={summaryData['CMOS_Address_Single_Line'] ?? summaryData['Meter_Address_Single_Line']} 
                  uprnEastingNorthing={summaryData && summaryData.ABP_Easting && summaryData.ABP_Northing ? [parseInt(summaryData.ABP_Easting), parseInt(summaryData.ABP_Northing)] : null} 
                  meterEastingNorthing={summaryData && summaryData.CL_GISX && summaryData.CL_GISY ? [parseInt(summaryData.CL_GISX), parseInt(summaryData.CL_GISY)] : null} 
                  displayExtraPins={true} 
                  matchedUprnText={true}
                  mdsMeterData={mdsMeterData}
                  showAllMeters={true}
                />
              </div>
              </>
            }
          </div>
        </GenericPageContainer>    
      )
})
