import { view } from '@risingstack/react-easy-state'
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import PropertyMap from '../../../components/property-map/property-map'
import GenericPageContainer from '../../../GenericPageElements/GenericPageContainer'
import request from '../../../helpers/request'
import hasPermission from '../../../helpers/permissions';
import session from '../../../stores/session'
import endpoints from '../../../helpers/endpoints'
import ReactModal from 'react-modal'
import { AddressBase, MeterAddress, CMOSLive, Summary, ValuationOfficeAndCouncilTax, CMOSFormatted, ConfirmModal, getMDSData } from '../AutomatedAddressCleanse/shared.jsx'
import { MDS } from '../OccupierTelephoneValidation/sharedComponents'

export default view(function InvalidPostcodeStreetSearch() {
  const [summaryData, setSummaryData] = useState({})
  const [selectedMatches, setSelectedMatches] = useState({abp: '', voa: '', ct: ''})
  const [confirmModal, setConfirmModal] = useState(false)
  const [mdsData, setMdsData] = useState([])
  const [mdsDataReady, setMdsDataReady] = useState(true)
  const [mdsMeterData, setMdsMeterData] = useState([])
  const [customMDSPostcode, setCustomMDSPostcode] = useState('')
  const [cmosUserInput, setCmosUserInput] = useState({})
  const [meterUserInput, setMeterUserInput] = useState({})
  const [disabledByUserType, setDisabledByUserType] = useState(true)
  const prefix = "INVALID_POSTCODE_STREET"
  const matchCategory = "Address_Status"

  const [translations, setTranslations] = useState({})
  useEffect(() => {
    request(true).get('/translate?key=mosl_eligibility')
    .then(e => {
      setTranslations(e.data)
    })
    }, [])
    useEffect(() => {
      setSelectedMatches({abp: '', voa: '', ct: ''})
    }, [summaryData.Core_SPID])

  return (
    <GenericPageContainer
      containerWidth={"95vw"}
      title="Invalid Postcode Street - Search"
      titleIconClass={"fak fa-id-business-icon fa-2x colour-primary"}
      titleRight={
        <div>
          <Link className="navigate" to="/portal/invalid-postcode-street">
              To Summary
          </Link>
          { (hasPermission('INVALID_POSTCODE_STREET', 'WORKFLOW')) ?
            <Link className="navigate" to="/portal/invalid-postcode-street/workflow">
                To Workflow
            </Link>
          : null }
        </div>
      }
    >
      <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
        <div style={{display: 'grid', gap: 10}}>
          <Summary translations={translations} summaryData={summaryData} setSummaryData={setSummaryData} setMdsData={setMdsData} getMDSData={() => getMDSData(summaryData, setMdsDataReady, prefix, customMDSPostcode, setMdsData, setMdsMeterData)} setMdsDataReady={setMdsDataReady} setConfirmModal={setConfirmModal} prefix={prefix} setDisabledByUserType={setDisabledByUserType} disabledByUserType={disabledByUserType} matchCategory={matchCategory} setMdsMeterData={setMdsMeterData} customMDSPostcode={customMDSPostcode} />
        </div>

        <div style={{display: 'grid', gridAutoColumns: '1fr', gridAutoFlow: 'column', gap: 10}}>
          <CMOSLive translations={translations} summaryData={summaryData} selectedMatches={selectedMatches} setSelectedMatches={setSelectedMatches} prefix={prefix} disabledByUserType={disabledByUserType} />
          <AddressBase translations={translations} summaryData={summaryData} selectedMatches={selectedMatches} setSelectedMatches={setSelectedMatches} prefix={prefix} disabledByUserType={disabledByUserType} setUserInput={setCmosUserInput} />
          <ValuationOfficeAndCouncilTax translations={translations} summaryData={summaryData} selectedMatches={selectedMatches} setSelectedMatches={setSelectedMatches} prefix={prefix} disabledByUserType={disabledByUserType} setUserInput={setCmosUserInput} />
          <MeterAddress translations={translations} summaryData={summaryData} selectedMatches={selectedMatches} setSelectedMatches={setSelectedMatches} prefix={prefix} disabledByUserType={disabledByUserType} userInput={meterUserInput} setUserInput={setMeterUserInput} />
          <CMOSFormatted translations={translations} summaryData={summaryData} selectedMatches={selectedMatches} prefix={prefix} disabledByUserType={disabledByUserType} userInput={cmosUserInput} setUserInput={setCmosUserInput} meterInput={meterUserInput} setSummaryData={setSummaryData} />
        </div>
        <div>
          <ConfirmModal confirmModal={confirmModal} setConfirmModal={setConfirmModal} prefix={prefix} summaryData={summaryData} setSummaryData={setSummaryData} cmosUserInput={cmosUserInput} meterUserInput={meterUserInput} />
        </div>
        <MDS data={ mdsData } MDSDataReady={ mdsDataReady } getData={ () => getMDSData(summaryData, setMdsDataReady, prefix, customMDSPostcode, setMdsData, setMdsMeterData) } customPostcode={ customMDSPostcode } setCustomPostcode={ setCustomMDSPostcode } style={{ fontSize: '14px' }} classes={"squish"} SPID={summaryData?.Core_SPID} /> 
        <div style={{minHeight: '40vh', marginBottom: '10em'}}>
          <PropertyMap 
            translations={translations} 
            eastingNorthing={summaryData && summaryData.CMOS_UPRN_ABP_GISX && summaryData.CMOS_UPRN_ABP_GISY && [parseInt(summaryData.CMOS_UPRN_ABP_GISX), parseInt(summaryData.CMOS_UPRN_ABP_GISY)]} 
            address={summaryData['CMOS_Address_Single_Line'] ?? summaryData['Meter_Address_Single_Line']} 
            uprnEastingNorthing={summaryData && summaryData.ABP_Easting && summaryData.ABP_Northing ? [parseInt(summaryData.ABP_Easting), parseInt(summaryData.ABP_Northing)] : null} 
            meterEastingNorthing={summaryData && summaryData.CL_GISX && summaryData.CL_GISY ? [parseInt(summaryData.CL_GISX), parseInt(summaryData.CL_GISY)] : null} 
            displayExtraPins={true} 
            matchedUprnText={true}
            mdsMeterData={mdsMeterData}
            showAllMeters={true}
          />
        </div>
      </div>
    </GenericPageContainer>
  )
})
