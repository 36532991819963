import React from "react";
import { useEffect, useState, useRef } from "react";
import ProductDataFilter from "../../../components/product-data-filter/product-data-filter";
import { SharedChartColours, LoadingForGraphTable } from "../../../GenericPageElements/GenericDataChart";
import GenericPageContainer from "../../../GenericPageElements/GenericPageContainer";
import GenericProductFilterContainer from "../../../GenericPageElements/GenericProductFilterContainer";
import endpoints from "../../../helpers/endpoints";
import hasPermission from '../../../helpers/permissions';
import request from "../../../helpers/request";
import S3Modal from "../../../components/s3Modal/S3Modal";
import session from "../../../stores/session";
import GenericDataRow from "../../../GenericPageElements/GenericDataRow";
import { view } from "@risingstack/react-easy-state";
import { Link } from "react-router-dom";
import BulkBuy from '../../../components/cot-alert-bulk-buy/bulkBuy';
import BulkAllocate from '../../../components/cot-alert-bulk-allocate/bulkAllocate';
import ReactModal from 'react-modal'
import './PortfolioSummary.scss';
import Dropzone from '../../../components/dropzone/dropzone'
import Filesaver from 'file-saver'
import Axios from "axios";
import Zoom from '@mui/material/Zoom';
import Text from 'recharts/lib/component/Text';
import { LineChart, BarChart, Line, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Legend, ResponsiveContainer, Tooltip } from 'recharts';

const CustomizedAxisTickMultiLine = ({x, y, payload}) => {
  return (
     <Text fill={"#666"} x={x} y={y + 5} width={120} textAnchor="middle" verticalAnchor="start" style={{fontSize: 12}}>{payload.value}</Text>
  )
};
const localised = Intl.NumberFormat("en-GB");

export default view(function PortfolioSummary() {
  const [filters, setFilters] = useState({});
  const [selectedGroupBy, setSelectectGroupBy] = useState("File_Date");
  const [data, setData] = useState(null);
  const [dateGranularity, setDateGranularity] = useState("Monthly")
  const [S3Model, setS3Model] = useState(null);
  const [bulkModel, setBulkModel] = useState('')
  const [search, setSearch] = useState('')
  const [files, setFiles] = useState({})
  const [uploadProgress, setUploadProgress] = useState({})
  const fileNames = [
    'MDS WSPID',
    'MDS SSPID',
    'MDS Meters',
    'MDS Read',
    'MDS LUM',
    'MOSL Vacancy',
    'MOSL Eligibility',
    'MOSL Premises',
    'Other'
  ]
  const [searchIsLoading, setSearchIsLoading] = useState(false)
  const [uploadIsLoading, setUploadIsLoading] = useState(false)
  const cancelTokenSourcesRef = useRef([]);
  const [searchResults, setSearchResults] = useState()
  const [spidFile, setSpidFile] = useState()
  const [spidSearchIsLoading, setSpidSearchIsLoading] = useState(false)
  const [translations, setTranslations] = useState({})
  const [highlightedLegendKey, setHighlightedLegendKey] = useState()
  const [toggledHighlightedLegendKeys, setToggledHighlightedLegendKeys] = useState([])
  const [enterEventDisabled, setEnterEventDisabled] = useState(false)
  const [tableType, setTableType] = useState('Line')

  useEffect(() => {
    request(true).get('/translate?key=mosl_eligibility')
    .then(e => {
      setTranslations(e.data)
    })
  }, [])

  const getData = () => {
    request(true).get(endpoints.PORTFOLIO_SUMMARY_DATA, {
      doesCancel: true,
      params: {
        dateGranularity: dateGranularity,
        filter: filters,
        group: selectedGroupBy,
      },
    })
    .then((e) => {
      setData(e.data);
    });
  }

  useEffect(() => {
    getData();
  }, [filters, selectedGroupBy, dateGranularity]);

  const InfoComponent = React.forwardRef(function InfoComponent(props, ref) {
    return (
      <i {...props} ref={ref} class="fa-regular fa-circle-info" />
    );
  });

  const handleMouseEnter = (event) => {
    if (enterEventDisabled) return
    const { dataKey } = event;
    setHighlightedLegendKey(dataKey)
  };

  const handleMouseLeave = (event) => {
    setEnterEventDisabled(false)
    setHighlightedLegendKey()
  };

  const handleOnClick = (event) => {
    const { dataKey } = event;
    setToggledHighlightedLegendKeys(prev => {
      const newToggled = prev.includes(dataKey)
        ? prev.filter(item => item !== dataKey)
        : [...prev, dataKey];
  
      return newToggled;
    });
    setHighlightedLegendKey()
    setEnterEventDisabled(true)
  }

  const checkForHighlight = (datakey) => {
    var highlightedExists = highlightedLegendKey || toggledHighlightedLegendKeys.length > 0
    if (!highlightedExists) {
      return null;
    }
    return (highlightedLegendKey && highlightedLegendKey === datakey) || (toggledHighlightedLegendKeys.includes(datakey)) 
  }

  const ChartComponent = tableType === 'Bar' ? BarChart : LineChart;

  const CustomTooltip = ({ active, payload, label, checkForHighlight }) => {
    if (active && payload && payload.length) {
      return (
        <div style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: '5px' }}>
          <p style={{ fontWeight: 'bold' }}>{label}</p>
          <ul style={{ listStyle: 'none', margin: 0, padding: 0 }}>
            {payload.map((item, index) => (
              <li
                key={index}
                style={{ fontWeight: checkForHighlight(item.dataKey) ? 'bold' : 'normal', color: SharedChartColours[index % SharedChartColours.length] }}
              >
                {item.dataKey}: {item.value}%
              </li>
            ))}
          </ul>
        </div>
      );
    }
    return null;
  };

  const cancelUpload = () => {
    cancelTokenSourcesRef.current.forEach(item => {
      item.source.cancel('Upload cancelled by user');
    });
    cancelTokenSourcesRef.current = [];
    setFiles({})
    setUploadProgress({})
    setSpidFile()
    setBulkModel('')
  }

  const LoadingBars = () => {
    return (
      Object.keys(uploadProgress).map(upload => {
        return (
          <div className="upload-progress-bars">
            <div className="progress-bar" style={{ "--progress": `${uploadProgress[upload]}%` }}>
            <p className="progress-text" style={{ "--progress": `${uploadProgress[upload]}%` }}>{uploadProgress[upload]}%</p>
            </div>
            <i onClick={() => {
              cancelTokenSourcesRef.current.find(item => item.file === upload).source.cancel()
              cancelTokenSourcesRef.current = cancelTokenSourcesRef.current.filter(item => item.file !== upload);
              const modifiedUploadProgress = {...uploadProgress}
              delete modifiedUploadProgress[upload];  
              setUploadProgress(modifiedUploadProgress)
            }} class="fa-solid fa-xmark fa-lg progress-upload-cancel"></i>
            <p>{upload}</p>
          </div>
        )
      })
    )
  }

  return [
    <GenericPageContainer
      title="Portfolio Summary"
      titleIconClass={"fak fa-id-business-icon fa-2x colour-primary"}
      containerWidth={'92vw'}
      titleRight={
        <div>
          <button style={{  gridColumn: 'span 2' }} className="navigate" onClick={() => setBulkModel('search')}>
            Single SPID Search
          </button>
          {hasPermission('PORTFOLIO_SUMMARY', 'UPLOAD') ? (
            <button className="navigate" onClick={() => setBulkModel("spid_search")}>
              Batch SPID Search
            </button>
          ) : null}
          {hasPermission('PORTFOLIO_SUMMARY', 'UPLOAD') ? (
            <button className="navigate" onClick={() => setBulkModel("mds_upload")}>
              MDS Upload
            </button>
          ) : null}
          {/* {hasPermission('PORTFOLIO_SUMMARY', 'UPLOAD') ? (
            <button className="navigate" onClick={() => setS3Model("upload")}>
              SPID Upload
            </button>
          ) : null}
          {hasPermission('PORTFOLIO_SUMMARY', 'DOWNLOAD') ? (
            <button className="navigate" onClick={() => setS3Model("download")}>
              SPID Download
            </button>
          ) : null} */}
        </div>
      }
    >
      <div style={{maxWidth: '20vw', marginBottom: 15}}>
      <GenericDataRow title={"Date View"} contentColour={"white"}>
        <select
          onChange={(_) => setDateGranularity(_.target.value)}
          value={dateGranularity}
          style={{
            width: "100%",
            height: "100%",
            border: "none",
            backgroundColor: "white",
            paddingLeft: "10px",
          }}
        >
          <option key={"Monthly"} value={"Monthly"}>Monthly</option>
          <option key={"Weekly"} value={"Weekly"}>Weekly</option>
        </select>
      </GenericDataRow>
      </div>
      <div className='PS'>
        <div style={{ maxHeight: 'unset', gridTemplateColumns: '2fr 2fr' }} className="vcv-table-or-graph using-graph grid grid-columns-2 grid-gap-15">
          { !data ?
            <div style={{ gridColumnEnd: 'span 5' }}>
              <LoadingForGraphTable />
            </div>
          :
            <>
              <table style={{ height: 'min-content' }} className="table borders squish smaller-text">
                <thead>
                  <tr>
                    <th>Date</th>
                    { data?.keys.map(key => {
                      return (
                        <th colSpan={2}>{key}</th>
                      )
                    })}
                  </tr>
                </thead>
                <tbody>
                  { Object.keys(data?.columns).map((column, index) => {
                    return (
                      <tr>
                        <td style={{ fontWeight: 'bolder' }}>{column}</td>
                        { data.keys.map(key => {
                          const currentGraphColumnIndex = data.graphColumns.findIndex(graphColumn => graphColumn.__columnName === column)
                          const currentData = data.graphColumns[currentGraphColumnIndex][key];
                          const previousData = data.graphColumns[currentGraphColumnIndex + 1]?.[key];
                          const dataDifference = currentData - previousData;
                          return (
                            <>
                              <td>{currentData}%</td>
                              <td>
                                {
                                  dataDifference === 0 ?
                                    <i style={{ color: 'var(--black)' }} class="fa-solid fa-equals"></i>
                                  : dataDifference > 0 ?
                                    <i style={{ color: 'var(--teal)' }} class="fa-solid fa-up"/>
                                  : previousData === undefined ?
                                    ""
                                  :
                                    <i style={{ color: 'var(--red)' }} class="fa-solid fa-down"/>
                                }
                              </td>  
                            </>
                          )
                        })}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
              <div style={{position: 'relative'}}>
                <i onClick={() => setTableType(tableType === 'Line' ? 'Bar' : 'Line')} style={{ zIndex: '100', position: 'absolute', left: '99%', cursor: 'pointer' }} class={`fa-solid fa-chart-${tableType === 'Line' ? 'column' : 'line'}`} />
                <ResponsiveContainer height={600} width='98%' debounce={1}>
                  <ChartComponent 
                    data={data?.graphColumns.map(column => {
                      const columnClone = structuredClone(column)
                      delete columnClone.Unmatched
                      return columnClone
                    }).reverse()}
                    margin={tableType === "Line" ? { top: 5, right: 50, left: 5, bottom: 5 } : {}}
                  >
                    <Legend iconType={tableType === "Line" ? "line" : "circle"} iconSize={tableType === "Line" ? 20 : 15} margin={20} 
                      onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}
                      onClick={handleOnClick}
                      wrapperStyle={{  paddingTop: '40px' }}
                      formatter={(value) => {
                        const highlighted = checkForHighlight(value)
                        return (
                          <span style={{ cursor: 'pointer', userSelect: 'none', fontWeight: highlighted ? 'bold' : 'unset' }}>{value}</span>
                        )
                      }}
                    />
                    <XAxis 
                      interval={0}
                      dataKey="__columnName"
                      padding={tableType === "Line" ? { left: 20, right: 20 } : {}}
                      tick={CustomizedAxisTickMultiLine}
                    />
                    <YAxis
                      type="number"
                      allowDecimals={false}
                      tick={{ fontSize: 12 }}
                      padding={{ top: 10 }}
                      tickFormatter={(item) => {
                        return localised.format(item) + "%";
                      }}
                    />
                    <CartesianGrid />
                    <Tooltip cursor={true} content={<CustomTooltip checkForHighlight={checkForHighlight}  />} />
                    {data?.keys.filter(key => key !== 'Unmatched').map((item, index) => {
                      const highlighted = checkForHighlight(item)
                      if (tableType === "Line") {
                        return (
                          <Line 
                            type={'monotone'}
                            dataKey={item}
                            stroke={SharedChartColours[index % SharedChartColours.length]}
                            strokeOpacity={highlighted === false ? 0.3 : 1}
                            strokeWidth={highlighted ? 3.5 : 2}
                            activeDot={{ r: 8 }}
                            dot={true}
                          />
                        );
                      } else if (tableType === "Bar") {
                        return (
                          <Bar
                            id={item}
                            dataKey={item}
                            fill={SharedChartColours[index % SharedChartColours.length]}
                            opacity={highlighted === false ? 0.3 : 1}
                            onMouseEnter={(event) => handleMouseEnter({dataKey: item})}
                            onMouseLeave={handleMouseLeave}
                          />
                        )
                      }
                    })}
                  </ChartComponent>
                </ResponsiveContainer>
              </div>
            </>
          }
        </div>
      </div>
    </GenericPageContainer>,
    <S3Modal
      closeModal={() => setS3Model(null)}
      modalMode={S3Model}
      showModal={!!S3Model}
      listingUrl={endpoints.PORTFOLIO_SUMMARY_FILES}
      uploadUrl={endpoints.PORTFOLIO_SUMMARY_FILES}
    />,
    <BulkBuy 
    SentenceFirst={({count}) => <>The selected data set includes {count} accounts to be downloaded. Press the 'Create File' button to create the download file and you will receive an email notification once this is available. Alternatively press the 'Cancel' button to go back to the summary screen.</>}
    SentenceSecond={() => <></>}
    purchaseLabel="Download Limit:"
    buyButtonText="Create File"
    completeOnFirstStep={true} 
    workflowUsersEndpoint={endpoints.PORTFOLIO_SUMMARY_WF_USERS} 
    bulkEndpoint={endpoints.PORTFOLIO_SUMMARY_BULK_BASE} 
    modalIsOpen={bulkModel === 'purchase'} 
    closeModal={() => setBulkModel('')} 
    filters={filters}
    />,
    // <BulkAllocate workflowUsersEndpoint={endpoints.PORTFOLIO_SUMMARY_WF_USERS} bulkEndpoint={endpoints.PORTFOLIO_SUMMARY_BULK_BASE} modalIsOpen={bulkModel === 'allocate'} closeModal={() => setBulkModel('')} filters={filters} date={date} />,
    <ReactModal
    isOpen={bulkModel === "search"}
    onRequestClose={() => setBulkModel('')}
    className="card bulk-allocate"
    contentLabel="SPID Search"
    style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' } }}
  >
    <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
      <h3>Search for a Core SPID</h3>
      <p>Please enter the Core SPID you wish to search for.</p>
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: '10px'}}>
        <input onChange={e => setSearch(e.target.value)} value={ search ?? '' }></input>
        <button className="button compact smaller-text cancel colour-white" onClick={() => setBulkModel('')} >Cancel</button>
        <Link 
        to={{ pathname: '/portal/portfolio-search', state: { spid: search } }}
        className="button compact smaller-text background-primary colour-white"
        style={{ textDecoration: 'none' }}
        disabled={!search || searchIsLoading}
        onClick={() => setSearchIsLoading(true)}
        >{searchIsLoading ?
          <div style={{ padding: '0em 1em' }} >
            <i className="fa fa-spinner fa-spin"></i>
          </div>
        :
          "Search"
        }</Link>
      </div>
    </div>
  </ReactModal>,
  <ReactModal
    isOpen={bulkModel === "mds_upload"}
    onRequestClose={() => setBulkModel('')}
    className="card bulk-allocate"
    contentLabel="MDS Upload"
    style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' } }}
  >
  <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
    <table className='table borders squish smaller-text left'>
      <thead>
        <th colSpan={2}>MDS Upload</th>
      </thead>
      <tbody>
        { fileNames.map((fileType, index) => {
          return (
            <tr>
              <td style={{ textAlign: "center" }} >{fileType}</td>
              <td style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                <Dropzone 
                style={{ background: files[index]?.name ? '#445468' : 'var(--gray)' }} 
                className="mds-upload-button"
                customText={files[index]?.name ?? ' Choose File'} 
                onDrop={(acceptedFiles) => {
                  if (acceptedFiles.length !== 1) {
                    window.alert('You may only upload one file at a time');
                    return
                  }
                  if (Object.values(files).find(item => item.name === acceptedFiles[0].name)) {
                    window.alert('File names must be unique');
                    return
                  }
                  setFiles({...files, [index]: acceptedFiles[0]})
                }} />
                {files[index] &&
                  <i onClick={() => {
                    const modifiedFiles = {...files}
                    delete modifiedFiles[index];  
                    setFiles(modifiedFiles)
                  }} style={{ position: 'absolute', color: 'black', cursor: 'pointer', marginRight: '-2em' }} class="fa-solid fa-xmark fa-lg"></i>
                }
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
      <button className="button compact smaller-text cancel colour-white" onClick={() => cancelUpload()} >Cancel</button>
      <button 
      className={`button compact smaller-text ${fileNames.length > 0 && !uploadIsLoading ? "background-primary colour-white" : ''}`} 
      disabled={fileNames.length < 1 || uploadIsLoading} 
      onClick={() => {
        setUploadIsLoading(true);
        if (window.confirm("By uploading files, you acknowledge and agree that you are responsible for the accuracy and legality of the data within the files. Identeq are not liable for any issues, including but not limited to copyright violations, privacy breaches, or data inaccuracies.")) {
          const errors = []
          request(true).post(endpoints.PORTFOLIO_SUMMARY_MDS_UPLOAD, {
            fileNames: Object.keys(files).reduce((acc, index) => {
              acc[fileNames[index]] = files[index].name;
              return acc;
            }, {}),
          }).then(r => {
            return Promise.all(
              Object.keys(files).map((fileCategory, index) => {
                if (uploadProgress[files[fileCategory].name]) return;
                const source = Axios.CancelToken.source();
                cancelTokenSourcesRef.current.push({file: files[fileCategory].name, source: source});
                return Axios.put(r.data.urls[index], files[fileCategory], {
                  headers: {
                    'Content-Type': files[fileCategory].type
                  },
                  cancelToken: source.token,
                  onUploadProgress: (progressEvent) => {
                    setUploadProgress((prev) => ({
                      ...prev,
                      [files[fileCategory].name]: Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    }));
                  }
                }).catch(e => {
                  console.log(e);
                  if (cancelTokenSourcesRef.current.find(item => item.file === files[fileCategory].name)) {
                    errors.push(files[fileCategory].name)
                  }
                })
              })
            );
          }).then(() => {
            if (errors.length > 0) {
              window.alert("Failed to upload files: \n" + errors.map(error => `${error}\n`).join(''));
            } else if (uploadProgress.length > 0) {
              cancelTokenSourcesRef.current = [];
              window.alert('Files successfully uploaded');
            }
            setUploadIsLoading(false);
          }).catch(e => {
            console.log(e);
            window.alert('Unexpected error occurred during file upload');
            setUploadIsLoading(false);
          });
        } else {
          setUploadIsLoading(false);
        }
      }}
        >
        {uploadIsLoading ?
            <div style={{ padding: '0em 1em' }} >
              <i className="fa fa-spinner fa-spin"></i>
            </div>
          :
            "Upload Files"
        }
      </button> 
    </div>
    <LoadingBars />
  </div>
</ReactModal>,
    <ReactModal
    isOpen={bulkModel === "spid_search"}
    onRequestClose={() => setBulkModel('')}
    className="card bulk-allocate"
    contentLabel="Batch SPID Search"
    style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' } }}
  >
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <Tooltip 
        placement="top"
        arrow
        TransitionComponent={Zoom}
        style={{ marginLeft: '5px', position: 'absolute' }}
        title={
          <p>
            <strong>File Upload Requirements:</strong>
  
            <ul>
              <li><strong>Accepted formats:</strong> Excel (.xlsx) or CSV (.csv)</li>
              <li><strong>Data structure:</strong> Full SPIDs must be listed in the first column</li>
              <li><strong>No other columns should be populated</strong></li>
              <li><strong>No header rows</strong> (The file should not include any column titles)</li>
              <li><strong>Maximum file size:</strong> 50,000 rows (lines)</li>
            </ul>

            <i>Please note: If more than 50,000 rows are uploaded, only the first 50,000 will be processed and returned.</i>
          </p>
        }>
          <InfoComponent />
        </Tooltip>
    </div>
    <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
      <h3>Batch SPID Search</h3>
      <p>Upload a list of SPIDs to receive a return file with the status for each.</p>
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: '10px'}}>
        <p>SPID List:</p>
        <Dropzone 
        style={{ background: spidFile?.name ? '#445468' : 'var(--gray)', minWidth: 'unset', width: '100%' }} 
        className="mds-upload-button"
        customText={spidFile?.name ?? ' Choose File'} 
        onDrop={(acceptedFiles) => {
          if (acceptedFiles.length !== 1) {
            window.alert('You may only upload one file at a time');
            return
          }
          setSpidFile(acceptedFiles[0])
        }} />
      </div>
      <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
        <button className="button compact smaller-text cancel colour-white" onClick={() => cancelUpload()} >Cancel</button>
        <button 
        className={`button compact smaller-text ${spidFile && !spidSearchIsLoading ? "background-primary colour-white" : ''}`} 
        disabled={!spidFile || spidSearchIsLoading} 
        onClick={() => {
          setSpidSearchIsLoading(true);
          request(true).post(endpoints.PORTFOLIO_SUMMARY_BATCH_SPID_UPLOAD, {
            fileName: spidFile.name
          }).then(r => {
            const source = Axios.CancelToken.source();
            cancelTokenSourcesRef.current.push({file: spidFile.name, source: source});
            Axios.put(r.data, spidFile, {
              headers: {
                  'Content-Type': spidFile.type
              },
              cancelToken: source.token,
              onUploadProgress: (progressEvent) => {
                setUploadProgress({...uploadProgress, [spidFile.name]: Math.round( (progressEvent.loaded * 100) / progressEvent.total )});
              }
            }).then(() => {
              window.alert('Files successfully uploaded')
              cancelTokenSourcesRef.current = cancelTokenSourcesRef.current.filter(item => item.source !== source);
              setSpidSearchIsLoading(false)
            }).catch(e => {
              console.log(e)
              if (cancelTokenSourcesRef.current && cancelTokenSourcesRef.current.length > 0) {
                window.alert('Error uploading files');
              }
              setSpidSearchIsLoading(false);
              cancelTokenSourcesRef.current = cancelTokenSourcesRef.current.filter(item => item.source !== source);
            })
          }).catch(e => {
            console.log(e)
            window.alert('Error uploading files');
            setSpidSearchIsLoading(false);
          })
        }}
        >
          {spidSearchIsLoading ?
              <div style={{ padding: '0em 1em' }} >
                <i className="fa fa-spinner fa-spin"></i>
              </div>
            :
              "Upload SPIDs"
          }
        </button> 
        <div style={{ gridColumn: '1/3' }}>
          <LoadingBars />
        </div>
      </div>
    </div>
  </ReactModal>,
  ];
})
