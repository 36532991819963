import React, { useEffect, useState, useRef } from "react";
import Checkbox from '@material-ui/core/Checkbox';
import Select from 'react-select'
import request from "../../helpers/request";
import ReactModal from 'react-modal'
import endpoints from "../../helpers/endpoints";
import { createMeasureOptions, completeOrAssuredRequest } from "../../pages/modules/AutomatedAddressCleanse/shared";

export default function VerifyButton({ summaryData = {}, setSummaryData, userInput = {}, meterUserInput, setUserInput, prefix }) {
    const [assuranceOptions, setassuranceOptions] = useState({
        PREMISE: false,
        ADDRESS: false,
        UPRN: false,
        VOA: false,
    })
    const [assuranceReasons, setAssuranceReasons] = useState({
        PREMISE: {},
        ADDRESS: {},
        UPRN: {},
        VOA: {},
    })
    const [assuranceComments, setAssuranceComments] = useState({
        PREMISE: '',
        ADDRESS: '',
        UPRN: '',
        VOA: '',
    })
    const [verificationResult, setVerificationResult] = useState({})
    const [isOpen, setIsOpen] = useState(false)
    const [buttonIsDisabled, setButtonIsDisabled] = useState(true)
    const [completeIsDisabled, setCompleteIsDisabled] = useState(false)
    const [searchIsLoading, setSearchIsLoading] = useState(false)
    const [fieldLoading, setFieldLoading] = useState()
    const [confirmIsLoading, setConfirmIsLoading] = useState(false)
    const prevUserInputRef = useRef();

    const updateassuranceOptions = (type) => {
        setassuranceOptions({
            ...assuranceOptions,
            [type]: !assuranceOptions[type]
        })
    }

    const replaceInputValue = (key) => {
        setFieldLoading(key)
        const type = key === "ABP_UPRN" ? "CF_UPRN" : "CF_VOA_BA_Reference" 
        setUserInput({
            ...userInput,
            [type]: verificationResult[key]
        })
    }

    const reset = () => {
        const keys = Object.keys(assuranceOptions)
        setassuranceOptions(keys.reduce((acc, curr) => (acc[curr] = false, acc), {}))
        setAssuranceReasons(keys.reduce((acc, curr) => (acc[curr] = {}, acc), {}))
        setAssuranceComments(keys.reduce((acc, curr) => (acc[curr] = '', acc), {}))
        setVerificationResult({})
        setIsOpen(false)
    }

    useEffect(() => {
        if (!isOpen) return
        if (JSON.stringify(prevUserInputRef.current) !== JSON.stringify(userInput)) {
            if (fieldLoading) {
                setVerificationResult({
                    ...verificationResult,
                    [fieldLoading]: ''
                })
            } else {
                setVerificationResult({})
            }
            setSearchIsLoading(true)
            request(true).post(endpoints[`${prefix}_VERIFY_ASSURANCE`],
                {
                    spid: summaryData.Core_SPID,
                    data: {
                        'Core_SPID': userInput.Core_SPID,
                        'Clean_Customer_Name': userInput.Clean_Customer_Name,
                        'VOA_BA_Reference': userInput.CF_VOA_BA_Reference ? userInput.CF_VOA_BA_Reference : userInput.CL_VOA_BA_Reference,
                        'UPRN': userInput.CF_UPRN ? userInput.CF_UPRN : userInput.CL_UPRN,
                        'Address_Line_1': Object.keys(userInput).filter(key => key.includes('CF_Address_Line')).some(key => userInput[key]) ? userInput.CF_Address_Line_1 : userInput.CL_Address_Line_1,
                        'Address_Line_2': Object.keys(userInput).filter(key => key.includes('CF_Address_Line')).some(key => userInput[key]) ? userInput.CF_Address_Line_2 : userInput.CL_Address_Line_2,
                        'Address_Line_3': Object.keys(userInput).filter(key => key.includes('CF_Address_Line')).some(key => userInput[key]) ? userInput.CF_Address_Line_3 : userInput.CL_Address_Line_3,
                        'Address_Line_4': Object.keys(userInput).filter(key => key.includes('CF_Address_Line')).some(key => userInput[key]) ? userInput.CF_Address_Line_4 : userInput.CL_Address_Line_4,
                        'Address_Line_5': Object.keys(userInput).filter(key => key.includes('CF_Address_Line')).some(key => userInput[key]) ? userInput.CF_Address_Line_5 : userInput.CL_Address_Line_5,
                        'Secondary_Addressable_Object': Object.keys(userInput).filter(key => key.includes('CF_Address_Line')).some(key => userInput[key]) ? userInput.CF_Secondary_Addressable_Object : userInput.CL_Secondary_Addressable_Object,
                        'Primary_Addressable_Object': Object.keys(userInput).filter(key => key.includes('CF_Address_Line')).some(key => userInput[key]) ? userInput.CF_Primary_Addressable_Object : userInput.CL_Primary_Addressable_Object,
                        'Postcode': userInput.CF_Postcode ? userInput.CF_Postcode : userInput.CL_Postcode
                    }
                },
                {
                    doesCancel: true
                }
            ).then(r => {
                setVerificationResult(r.data)
                prevUserInputRef.current = userInput;
                setSearchIsLoading(false)
                setFieldLoading()
            }).catch(error => {
                console.log(error)
                window.alert("Failed to verify user input.")
                setSearchIsLoading(false)
                setFieldLoading()
            })
        }
    }, [isOpen, userInput, summaryData.Core_SPID, prefix])

    useEffect(() => {
        var isDisabled = false;
        ['VOA_BA_Reference', 'UPRN', 'Postcode'].forEach(column => {
            if (!userInput[`CF_${column}`]) {
                if (!userInput[`CL_${column}`]) {
                    isDisabled = true;
                }
            } 
        })
        setButtonIsDisabled(isDisabled)
    }, [userInput])

    useEffect(() => {
        setAssuranceReasons(prev => {
            const newReasons = { ...prev };
            Object.keys(assuranceOptions).forEach(option => {
                if (!assuranceOptions[option]) {
                    newReasons[option] = {};
                }
            });
            return newReasons;
        });
    }, [assuranceOptions]);

    useEffect(() => {
        setAssuranceComments(prev => {
            const newComments = { ...prev };
            Object.keys(assuranceReasons).forEach(type => {
                if (!assuranceReasons[type].commentBox) {
                    newComments[type] = "";
                }
            });
            return newComments;
        })
    }, [assuranceReasons])

    useEffect(() => {
        if (searchIsLoading) {
            setCompleteIsDisabled(true)
            return
        }
        if (Object.values(assuranceOptions).every(option => option === false)) {
            setCompleteIsDisabled(false)
            return
        }
        Object.keys(assuranceOptions).forEach(option => {
            if (assuranceOptions[option]) {
                setCompleteIsDisabled(!assuranceReasons[option].value)
            }
        })
    }, [assuranceOptions, assuranceReasons, searchIsLoading])

    return [
        <button style={{ width: '100%' }} disabled={buttonIsDisabled} className={`button ${!buttonIsDisabled ? 'background-primary colour-white' : ''}`} onClick={() => setIsOpen(true)}>Verify Changes</button>,
        <ReactModal
            isOpen={isOpen}
            onRequestClose={() => setIsOpen(false)}
            className="card bulk-allocate"
            contentLabel="Verify Changes"
            style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' }, content: { maxWidth: '80vw' } }}
        >
            <div className="verify-button-popup">
                <table className="table borders">
                    <thead>
                        <tr>
                            <th style={{ width: '8em' }}>Element</th>
                            <th style={{ width: '8em' }}>Result</th>
                            <th style={{ width: '10em' }}>Suggestion</th>
                            <th style={{ width: '5em' }}>Assure</th>
                            <th style={{ width: '32em' }}>Assure Reason</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Premises</td>
                            <td>
                                {
                                    searchIsLoading && !fieldLoading ?
                                        <i className="fa fa-spinner fa-spin"></i>
                                    :
                                        verificationResult.DQA_Premise
                                }
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Address</td>
                            <td>{verificationResult.DQA_Address}</td>
                            <td></td>
                            <td>
                                <Checkbox 
                                    key={"address_assured"}
                                    color='primary'
                                    checked={assuranceOptions['ADDRESS']}
                                    onChange={() => updateassuranceOptions('ADDRESS')}
                                    disabled={searchIsLoading}
                                />
                            </td>
                            <td>
                                <Select
                                    options={createMeasureOptions()["address_options"]}
                                    onChange={(selectedOption) => {setAssuranceReasons({...assuranceReasons, ADDRESS: selectedOption})}}
                                    value={assuranceReasons.ADDRESS}
                                    placeholder="Select Assurance Reason"
                                    isDisabled={!assuranceOptions.ADDRESS}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>UPRN</td>
                            <td>
                                {
                                    searchIsLoading && fieldLoading === "ABP_UPRN" ?
                                        <i className="fa fa-spinner fa-spin"></i>
                                    :
                                        verificationResult.DQA_UPRN
                                }
                            </td>
                            <td>{verificationResult.ABP_UPRN}</td>
                            <td>
                                <Checkbox 
                                    key={"uprn_assured"}
                                    color='primary'
                                    checked={assuranceOptions['UPRN']}
                                    onChange={() => updateassuranceOptions('UPRN')}
                                    disabled={searchIsLoading}
                                />
                            </td>
                            <td>
                                <Select
                                    options={createMeasureOptions("UPRN")["uprn_voa_options"]}
                                    onChange={(selectedOption) => {setAssuranceReasons({...assuranceReasons, UPRN: selectedOption})}}
                                    value={assuranceReasons.UPRN}
                                    placeholder="Select Assurance Reason"
                                    isDisabled={!assuranceOptions.UPRN}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>VOA</td>
                            <td>
                                {
                                    searchIsLoading && fieldLoading === "BR_Reference_Number" ?
                                        <i className="fa fa-spinner fa-spin"></i>
                                    :
                                        verificationResult.DQA_VOA
                                }
                            </td>
                            <td>{verificationResult.BR_Reference_Number}</td>
                            <td>
                                <Checkbox 
                                    key={"voa_assured"}
                                    color='primary'
                                    checked={assuranceOptions['VOA']}
                                    onChange={() => updateassuranceOptions('VOA')}
                                    disabled={searchIsLoading}
                                />
                            </td>
                            <td>
                                <Select
                                    options={createMeasureOptions("VOA")["uprn_voa_options"]}
                                    onChange={(selectedOption) => {setAssuranceReasons({...assuranceReasons, VOA: selectedOption})}}
                                    value={assuranceReasons.VOA}
                                    placeholder="Select Assurance Reason"
                                    isDisabled={!assuranceOptions.VOA}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                {
                    (assuranceReasons.UPRN.commentBox || assuranceReasons.VOA.commentBox) &&
                        <div className="comments">
                            { ['UPRN', 'VOA'].map((type) => {
                                const reducedRows = assuranceReasons.UPRN.commentBox && assuranceReasons.VOA.commentBox
                                return (
                                    assuranceReasons[type].commentBox && 
                                        <div>
                                            <label>{type} Comment:</label>
                                            <textarea onChange={(event) => setAssuranceComments({...assuranceComments, [type]: event.target.value.slice(0, 500)})} value={assuranceComments[type] ?? ''} rows={reducedRows ? 6 : 8} style={{height: '100%', width: '100%', resize: 'none'}}/>  
                                            <div style={{ position: 'relative', bottom: '3px', textAlign: 'end', color: 'gray', fontSize: 12 }}>
                                                {assuranceComments[type].length}/500
                                            </div>
                                        </div>
                                )
                            })}
                        </div>
                }
                <div className="verify-button-options">
                    <button className="button bulk-buy-button compact smaller-text" onClick={() => setIsOpen(false)}>Close</button>
                    <button className={`button ${verificationResult.ABP_UPRN && verificationResult.ABP_UPRN !== userInput.CF_UPRN?.toString() ? "bulk-buy-button" : ''} compact smaller-text`} disabled={!verificationResult.ABP_UPRN || verificationResult.ABP_UPRN === userInput.CF_UPRN?.toString() }  onClick={() => replaceInputValue("ABP_UPRN")}>Replace UPRN</button>
                    <button className={`button ${verificationResult.BR_Reference_Number && verificationResult.BR_Reference_Number !== userInput.CF_VOA_BA_Reference?.toString() ? "bulk-buy-button" : ''} compact smaller-text`} disabled={!verificationResult.BR_Reference_Number || verificationResult.BR_Reference_Number === userInput.CF_VOA_BA_Reference?.toString()} onClick={() => replaceInputValue("BR_Reference_Number")}>Replace VOA</button>
                    <button className={`button ${!completeIsDisabled ? "bulk-buy-button" : ''} compact smaller-text`} disabled={completeIsDisabled} onClick={() => completeOrAssuredRequest(setConfirmIsLoading, prefix, '', summaryData, Object.values(assuranceOptions).some(option => option === true) ? 'Assured' : 'Complete', userInput, meterUserInput, assuranceReasons, assuranceComments, setSummaryData, reset)} >
                        {
                            confirmIsLoading ? 
                                <i className="fa fa-spinner fa-spin"></i>
                            :
                                "Complete"
                        }
                    </button>
                </div>
            </div>
        </ReactModal>
    ]
}