import { view } from '@risingstack/react-easy-state'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import PropertyMap from '../../../components/property-map/property-map'
import GenericPageContainer from '../../../GenericPageElements/GenericPageContainer'
import request from '../../../helpers/request'
import session from '../../../stores/session'
import endpoints from '../../../helpers/endpoints'
import ReactModal from 'react-modal'
import { CurrentMeter, Summary, syncRowHeights } from '../MeterCoordinatesValidation/shared.jsx'
import { AddressBase, MeterAddress, CMOSLive, ValuationOfficeAndCouncilTax, CMOSFormatted, ConfirmModal } from '../AutomatedAddressCleanse/shared.jsx'
import { MDS } from '../OccupierTelephoneValidation/sharedComponents'

export default view(function ManagedServiceMetersWorkflow() {
  const [summaryData, setSummaryData] = useState({})
  const [selectedMatches, setSelectedMatches] = useState({abp: '', voa: '', ct: ''})
  const [confirmModal, setConfirmModal] = useState(false)
  const [mdsData, setMdsData] = useState([])
  const [mdsDataReady, setMdsDataReady] = useState(true)
  const [customMDSPostcode, setCustomMDSPostcode] = useState('')
  const [loadingPremises, setLoadingPremises] = useState(false)
  const [premisesData, setPremisesData] = useState({})
  const [addressType, setAddressType] = useState("");
  const [cmosUserInput, setCmosUserInput] = useState({});
  const [meterUserInput, setMeterUserInput] = useState({})
  const [disabledByUserType, setDisabledByUserType] = useState(true)
  const prefix = "MANAGED_SERVICE"
  const suffix = "?isMeter=true"
  const [meterOrCmos, setMeterOrCmos] = useState("meter")

  const [translations, setTranslations] = useState({})
  useEffect(() => {
    request(true).get('/translate?key=mosl_eligibility')
    .then(e => {
      setTranslations(e.data)
    })
    }, [])
    useEffect(() => {
      setSelectedMatches({abp: '', voa: '', ct: ''})
    }, [summaryData.Core_SPID])
    
  const getMDSData = () => {
    if (!summaryData || !summaryData.Core_SPID) return
    setMdsDataReady(false);
    request(true).get(endpoints[`${prefix}_MDS_SPID`], {
      doesCancel: true,
      params: {
          postcode: customMDSPostcode || summaryData.CF_Postcode || summaryData.CL_Postcode || summaryData.CL_Meter_Address_Postcode
      }
    }).then(r => {
      setMdsData(r.data)
      setMdsDataReady(true)
    }).catch(e => {
      setMdsDataReady(true)
        console.log(e);
    })
  }

  useEffect(() => {
    setAddressType(summaryData.CF_Meter_Address_Address_Line_1 ? 'meter' : summaryData.CF_Address_Line_1 ? 'premises' : "")
  }, [summaryData.Meter_ID])

  const getPremisesData = () => {
    setLoadingPremises(true)
    // setUserInput({})
    request(true).get(endpoints[`${prefix}_GET_PREMISES`], {
      doesCancel: true,
      params: {
        SPID: summaryData.Core_SPID
      }
    }).then(r => {
      setPremisesData(r.data)
      setLoadingPremises(false)
    }).catch(e => {
      console.log(e)
      setLoadingPremises(false)
    })
  }

  useEffect(() => {
    if (summaryData && summaryData.Meter_ID) {
      getPremisesData()
    } else {
      setPremisesData({})
      setLoadingPremises(false)
    }
  }, [summaryData.Meter_ID])

  useEffect(() => {
    syncRowHeights()
  }, [premisesData, mdsData, selectedMatches, meterOrCmos])

  return (
    <GenericPageContainer
      containerWidth={"95vw"}
      title="Managed Service - Meters Workflow"
      titleIconClass={"fak fa-id-business-icon fa-2x colour-primary"}
      titleRight={
        <div>
          <Link className="navigate" to="/portal/managed-service">
              To Summary
          </Link>
          <Link className="navigate" to="/portal/managed-service/premises-workflow">
              Premises Workflow
          </Link>
        </div>
      }
    >
      <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
        <div style={{display: 'grid', gap: 10}}>
          <Summary translations={translations} summaryData={summaryData} setSummaryData={setSummaryData} workflow={true} setMdsData={setMdsData} setMdsDataReady={setMdsDataReady} getMDSData={getMDSData} setConfirmModal={setConfirmModal} userInput={cmosUserInput} setAddressType={setAddressType} prefix={prefix} setDisabledByUserType={setDisabledByUserType} suffix={suffix} />
        </div>

        <div style={{display: 'grid', gridAutoColumns: '1fr', gridAutoFlow: 'column', gap: 10}}>
          { meterOrCmos === "meter" ?
            <CurrentMeter translations={translations} summaryData={summaryData} selectedMatches={selectedMatches} setSelectedMatches={setSelectedMatches} premisesData={premisesData} loading={loadingPremises} toggle={() => setMeterOrCmos('cmos')} />
            :
            <CMOSLive translations={translations} summaryData={premisesData} selectedMatches={selectedMatches} setSelectedMatches={setSelectedMatches} prefix={prefix} disabledByUserType={disabledByUserType} toggle={() => setMeterOrCmos('meter')} />
          }
          <AddressBase translations={translations} summaryData={summaryData} selectedMatches={selectedMatches} setSelectedMatches={setSelectedMatches} prefix={prefix} disabledByUserType={disabledByUserType} setUserInput={setCmosUserInput} />
          <ValuationOfficeAndCouncilTax translations={translations} summaryData={summaryData} selectedMatches={selectedMatches} setSelectedMatches={setSelectedMatches} prefix={prefix} disabledByUserType={disabledByUserType} setUserInput={setCmosUserInput} />
          <MeterAddress translations={translations} summaryData={summaryData} selectedMatches={selectedMatches} setSelectedMatches={setSelectedMatches} prefix={prefix} disabledByUserType={disabledByUserType} userInput={meterUserInput} setUserInput={setMeterUserInput} />
          <CMOSFormatted translations={translations} summaryData={summaryData} selectedMatches={selectedMatches} prefix={prefix} disabledByUserType={disabledByUserType} userInput={cmosUserInput} setUserInput={setCmosUserInput} meterInput={meterUserInput} setSummaryData={setSummaryData} />
        </div>
        <div>
          <ConfirmModal confirmModal={confirmModal} setConfirmModal={setConfirmModal} prefix={prefix} summaryData={summaryData} setSummaryData={setSummaryData} cmosUserInput={cmosUserInput} meterUserInput={meterUserInput} suffix={suffix} />
        </div>
        <MDS data={ mdsData } MDSDataReady={ mdsDataReady } getData={ getMDSData } customPostcode={ customMDSPostcode } setCustomPostcode={ setCustomMDSPostcode } style={{ fontSize: '14px' }} classes={"squish"} SPID={summaryData?.Core_SPID} /> 
        <div style={{minHeight: '40vh', marginBottom: '10em'}}>
          <PropertyMap 
            translations={translations} 
            eastingNorthing={summaryData && summaryData.CMOS_UPRN_ABP_GISX && summaryData.CMOS_UPRN_ABP_GISY && [parseInt(summaryData.CMOS_UPRN_ABP_GISX), parseInt(summaryData.CMOS_UPRN_ABP_GISY)]} 
            address={summaryData['CMOS_Address_Single_Line'] ?? summaryData['Meter_Address_Single_Line']} 
            uprnEastingNorthing={summaryData && summaryData.ABP_Easting && summaryData.ABP_Northing && [parseInt(summaryData.ABP_Easting), parseInt(summaryData.ABP_Northing)]} 
            meterEastingNorthing={summaryData && summaryData.CL_GISX && summaryData.CL_GISY && [parseInt(summaryData.CL_GISX), parseInt(summaryData.CL_GISY)]} 
            displayExtraPins={true} 
            matchedUprnText={true}
          />
        </div>
      </div>
    </GenericPageContainer>    
  )
})
