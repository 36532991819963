import React, { useEffect, useState, useMemo } from "react";
import ProductDataFilter from "../../../components/product-data-filter/product-data-filter";
import GenericPageContainer from "../../../GenericPageElements/GenericPageContainer";
import GenericProductFilterContainer from "../../../GenericPageElements/GenericProductFilterContainer";
import endpoints from "../../../helpers/endpoints";
import hasPermission from '../../../helpers/permissions';
import request from "../../../helpers/request";
import S3Modal from "../../../components/s3Modal/S3Modal";
import GenericDataRow from "../../../GenericPageElements/GenericDataRow";
import { view } from "@risingstack/react-easy-state";
import './AddressAssessment.scss';
import { LoadingForGraphTable, SharedChartColours } from "../../../GenericPageElements/GenericDataChart";
import DataGroupings from "../../../components/data-groupings/data-groupings";
import ProductChartInfo from "../../../components/product-chart-info/product-info-chart";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Legend, ResponsiveContainer, Tooltip } from 'recharts';
import ReactModal from 'react-modal'
import { Link } from "react-router-dom";
import BulkBuy from '../../../components/cot-alert-bulk-buy/bulkBuy';
import BulkAllocate from '../../../components/cot-alert-bulk-allocate/bulkAllocate';
const localised = Intl.NumberFormat("en-GB");

export default view(function AddressAssessment() {
  const [filters, setFilters] = useState({});
  const [selectedGroupBy, setSelectectGroupBy] = useState("");
  const [data, setData] = useState(null);
  const [dates, setDates] = useState([]);
  const [date, setDate] = useState(null);
  const [groupings, setGroupings] = useState([]);
  const [displayMode, setDisplayMode] = useState('graph')
  const [dataReady, setDataReady] = useState(false)

  const [S3Model, setS3Model] = useState(null);
  const [bulkModel, setBulkModel] = useState('')
  const [search, setSearch] = useState('')
  const [searchIsLoading, setSearchIsLoading] = useState(false)

  useEffect(() => {
    request(true).get(endpoints.ADDRESS_ASSESSMENT_GROUPINGS).then(e => {
      if (e.status === 200) {
        setGroupings(e.data)
      }
    })
  }, [])
  
  const applyFilter = (column, value) => {
    setFilters((current) => {
      return {
        ...current,
        [column]: value,
      };
    });
  };

  useEffect(() => {
    request(true)
      .get(endpoints.ADDRESS_ASSESSMENT_DATES)
      .then((e) => {
        setDates(e.data.dates);
        if (e.data.dates.length > 0) setDate("All_Refreshes");
      });
  }, []);

  useEffect(() => {
    if (!date) return;
    request(true)
      .get(endpoints.ADDRESS_ASSESSMENT_DATA, {
        doesCancel: true,
        params: {
          date: date,
          filter: filters,
          group: selectedGroupBy,
        },
      })
      .then((e) => {
        setData(e.data);
        setDataReady(true)
      });
  }, [filters, selectedGroupBy, date]);

  const totals = useMemo(() => {
    if (!data || data.keys.length < 1) return []
    let totalsArray = new Array(data.data[data.keys[0]].length).fill(0)
    totalsArray = totalsArray.map((totals, index) => {
      let total = 0;
      data.keys.forEach(key => {
        total += data.data[key][index]
      });
      return (
        total
      )
    })
    totalsArray.push(Object.values(data.groupTotals).reduce((partialSum, a) => partialSum + a, 0))
    return totalsArray
  }, [data])

  const CustomTooltip = ({ active, payload, label, data }) => {
    if (!active || !payload || !payload.length || !data) return null;
    const group = payload[0].payload.group;
    const groupData = data?.graphColumns.filter(item => item.group === group) ?? [];
    
    return (
      <div style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: 10 }}>
        <h4>{group}</h4>
        {groupData.map((item) => {
          return (
            Object.keys(data.columns).map((column, index) => {
              return (
                <>
                  <h5 style={{ margin: '0px' }}>{column}</h5>
                  <ul style={{ margin: '0 0 1em' }}>
                    { Object.keys(item).filter(key => key.includes(column + '-', 0)).map((groupName, columnIndex) => {
                      return (
                        <li style={{ color: SharedChartColours[columnIndex % SharedChartColours.length] }}>{groupName.substring(column.length + 1)}: {item[groupName]}</li>
                      )   
                    })}
                  </ul>
                </>
              )
            })
          )
        })}
      </div>
    );
  };

  return (
    <GenericPageContainer
      title="Address Assessment"
      titleIconClass={"fak fa-id-business-icon fa-2x colour-primary"}
      containerWidth={'92vw'}
      titleRight={
        <div>
          <button style={{  gridColumn: 'span 2' }} className="navigate" onClick={() => setBulkModel('search')}>
            Single SPID Search
          </button>
          {hasPermission('ADDRESS_ASSESSMENT', 'DOWNLOAD') ? (
            <button className="navigate" onClick={() => setS3Model("download")}>
              Download Return File
            </button>
          ) : null}
        </div>
      }
    >
      <div style={{maxWidth: '20vw', marginBottom: 15}}>
        <GenericDataRow title={"File Date"} contentColour={"white"}>
          <select
            onChange={(_) => setDate(_.target.value)}
            value={date}
            style={{
              width: "100%",
              height: "100%",
              border: "none",
              backgroundColor: "white",
              paddingLeft: "10px",
            }}
          >
            <option key={"All_Refreshes"} value={"All_Refreshes"}>All Refreshes</option>
            {dates.map((item) => {
              return (
                <option key={item.raw} value={item.raw}>
                  {item.pretty}
                </option>
              );
            })}
          </select>
        </GenericDataRow>
      </div>
      <div className='AddressAssessment'>
        <div>
          <div className="table-format" style={{display: 'grid', gridTemplateColumns: 'auto 200px', gap: 15, marginBottom: 15}}>
            <div className={`data-row group-list ${selectedGroupBy}-active`}>
              <GenericDataRow title={'Group by:'}>
                <DataGroupings
                  group={selectedGroupBy}
                  groupings={groupings}
                  setGroup={setSelectectGroupBy}
                />
              </GenericDataRow>
            </div>
            <div className="data-row display-type">
              <select
                onChange={(_) => setDisplayMode(_.target.value)}
                value={displayMode}
                style={{
                  width: "100%",
                  height: "100%",
                  border: "none",
                  backgroundColor: "white",
                  paddingLeft: "10px",
                }}
              >
                <option value="table">Table</option>
                <option value="graph">Graph</option>
              </select>
              <ProductChartInfo path={window.location.pathname} />
            </div>
          </div>
          <div className={`vcv-table-or-graph using-${displayMode}`}>
            { !dataReady ? (
              <LoadingForGraphTable />
            ) : (
              <>
                { displayMode === "table" ? (
                  <table className="table borders">
                    <thead>
                      <tr>
                        <th></th>
                        { data.defaultGroup.map(group => {
                          return (
                            <th colSpan={Object.keys(data.columns).length}>{group}</th>
                          )
                        })}
                        <th></th>
                      </tr>
                      <tr>
                        <th>{groupings.find(group => group.target_column === selectedGroupBy)?.title}</th>
                        {data.defaultGroup.map(group => {
                          return (
                            Object.keys(data.columns).map((columName, index) => {
                              const borderStyling = 'white 1px solid'
                              return (
                                <th style={{ borderTop: borderStyling, borderLeft: index === 0 && borderStyling, borderRight: index === Object.keys(data.groupTotals).length - 1 && borderStyling }}>
                                  {columName}
                                </th>
                              )
                            })
                          )
                        })}
                        <th className="no-radius bold">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.keys.map(item => {
                        return (
                          <tr key={`summary-data-${Math.random()}`}>
                            <td>{item}</td>
                            {data.data[item].map(value => {
                              return (
                                <td>{localised.format(value)}</td>
                              )
                            })}
                            <td>{localised.format(data.groupTotals[item])}</td>
                          </tr>
                        )
                      })}
                      <tr>
                        <td>Total</td>
                        {
                          totals.map(total => {
                            return (
                            <td>{total}</td>
                            )
                          })
                        }
                      </tr>
                    </tbody>
                  </table>
                ) : (
                  <ResponsiveContainer height={600} width='98%' debounce={1}>
                    <BarChart 
                      data={data?.graphColumns}
                      barGap={50}
                    >
                      <Legend iconType={"circle"} iconSize={15} margin={20} 
                        wrapperStyle={{  paddingTop: '40px' }}
                        payload={data.keys.map((key, index) => {
                          return (
                            {value: key, id: index, type: 'circle', color: SharedChartColours[index % SharedChartColours.length]}
                          )
                        })}
                        formatter={(value) => {
                          return (
                            <span>{value}</span>
                          )
                        }}
                      />
                      <XAxis dataKey="group" 
                      tick={({ x, y, payload }) => {
                        // Evenly space sublabels based on barwidth of 50, barsize of 50
                        const xValues = Array.from({ length: Object.keys(data.columns).length }, (_, i) => (i - (Object.keys(data.columns).length - 1) / 2) * 100); 
                        return (
                          <>
                            <line x1={x + xValues[0] - 50} y1={y - 8} x2={x + xValues[0] - 50} y2={y + 15} stroke="#666" strokeWidth={1} />
                            <line x1={x + xValues[xValues.length - 1] + 50} y1={y - 8} x2={x + xValues[xValues.length - 1] + 50} y2={y + 15} stroke="#666" strokeWidth={1} />
                            <g transform={`translate(${x}, ${y})`}>
                              <text x={0} y={0} dy="0.71em" textAnchor="middle" fill="#666" fontWeight="bold">
                                {payload.value}
                              </text>
                              { Object.keys(data.columns).map((col, index) => {
                                return (
                                  <text key={col} x={xValues[index]} y={45} textAnchor="middle" fontSize={12}>
                                    {col}
                                  </text>
                                )
                              })}
                            </g>
                          </>
                        )
                      }}
                      />
                      <YAxis
                        type="number"
                        allowDecimals={false}
                        tick={{ fontSize: 12 }}
                        tickFormatter={(item) => {
                          return localised.format(item);
                        }}
                      />
                      <CartesianGrid />
                      <Tooltip cursor={true} content={<CustomTooltip data={data}/> } />
                      {
                        data.keys.map((key, keyIndex) => {
                          return (
                            Object.keys(data.columns).map((column, columnIndex) => {
                              const totalIndex = keyIndex * Object.keys(data.columns).length + columnIndex
                              return (
                                <Bar barSize={50} key={totalIndex} dataKey={`${column}-${key}`} stackId={column} fill={SharedChartColours[keyIndex % SharedChartColours.length]} />
                              );
                            })
                          );
                        })
                      }
                    </BarChart>
                  </ResponsiveContainer>
                )}
              </>
            )}
          </div>
        </div>
        <GenericProductFilterContainer clearFilters={() => setFilters({})}>
          <ProductDataFilter
            filterState={filters}
            applyFilter={applyFilter}
            dataFilterEndpoint={endpoints.ADDRESS_ASSESSMENT_FILTERS}
          />
          <div className='grid grid-columns-2 grid-gap-5 mta'>
              { (hasPermission('ADDRESS_ASSESSMENT', 'ALLOCATE')) ?
                  <button
                    className="button bulk-buy-button compact smaller-text" onClick={() => setBulkModel('allocate_priority_cleansing')}>
                      Allocate to Priority Cleansing
                  </button>
              : '' }
              { (hasPermission('ADDRESS_ASSESSMENT', 'ALLOCATE')) ?
                  <button 
                    className="button bulk-buy-button compact smaller-text" onClick={() => setBulkModel('allocate_managed_service')}>
                      Allocate to Managed Service
                  </button>
              : '' }
              { (hasPermission('ADDRESS_ASSESSMENT', 'BULK-BUY')) ?
                  <button style={{  gridColumn: 'span 2' }} className="button bulk-buy-button compact smaller-text" onClick={() => setBulkModel('purchase')}>
                      Create Download File
                  </button>
              : '' }
          </div>
        </GenericProductFilterContainer>
      </div>
      <S3Modal
        closeModal={() => setS3Model(null)}
        modalMode={S3Model}
        showModal={!!S3Model}
        listingUrl={endpoints.AUTOMATED_ADDRESS_CLEANSE_FILES}
        uploadUrl={endpoints.AUTOMATED_ADDRESS_CLEANSE_FILES}
      />
      <BulkBuy 
          SentenceFirst={({count}) => <>The selected data set includes {count} accounts to be downloaded. Press the 'Create File' button to create the download file and you will receive an email notification once this is available. Alternatively press the 'Cancel' button to go back to the summary screen.</>}
          SentenceSecond={() => <></>}
          purchaseLabel="Download Limit:"
          buyButtonText="Create File"
          completeOnFirstStep={true} 
          workflowUsersEndpoint={endpoints.ADDRESS_ASSESSMENT_WF_USERS} 
          bulkEndpoint={endpoints.ADDRESS_ASSESSMENT_BULK_BASE} 
          modalIsOpen={bulkModel === 'purchase'} 
          closeModal={() => setBulkModel('')} 
          filters={filters} 
          date={date} 
        />
      <BulkAllocate 
        workflowUsersEndpoint={endpoints.ADDRESS_ASSESSMENT_WF_USERS + `?module=${bulkModel.substring(bulkModel.indexOf('_') + 1)}`} 
        bulkEndpoint={endpoints.ADDRESS_ASSESSMENT_BULK_BASE} 
        modalIsOpen={bulkModel === 'allocate_priority_cleansing' || bulkModel === 'allocate_managed_service'} 
        closeModal={() => setBulkModel('')} 
        filters={filters} 
        date={date} 
        urlParams={{ module: bulkModel.substring(bulkModel.indexOf('_') + 1) }}
        mode={"priority"}
        tipSentence={
          `The chosen number of accounts will be moved into the 
          ${bulkModel.substring(bulkModel.indexOf('_') + 1)
            .split('_')
            .map(item => item.charAt(0).toUpperCase() + item.substring(1))
            .join(' ')} 
          module.`}
        />
      <ReactModal
        isOpen={bulkModel === "search"}
        onRequestClose={() => setBulkModel('')}
        className="card bulk-allocate"
        contentLabel="SPID Search"
        style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' } }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
          <h3>Search for a Core SPID</h3>
          <p>Please enter the Core SPID you wish to search for.</p>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: '10px'}}>
            <input onChange={e => setSearch(e.target.value)} value={ search ?? '' }></input>
            <button className="button compact smaller-text cancel colour-white" onClick={() => setBulkModel('')} >Cancel</button>
            <Link 
            to={{ pathname: '/portal/address-assessment-search', state: { spid: search } }}
            className="button compact smaller-text background-primary colour-white"
            style={{ textDecoration: 'none' }}
            disabled={!search || searchIsLoading}
            onClick={() => setSearchIsLoading(true)}
            >{searchIsLoading ?
              <div style={{ padding: '0em 1em' }} >
                <i className="fa fa-spinner fa-spin"></i>
              </div>
            :
              "Search"
            }</Link>
          </div>
        </div>
      </ReactModal>
    </GenericPageContainer>
  );
})
