import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import ProductDataFilter from "../../../components/product-data-filter/product-data-filter";
import GenericDataChart from "../../../GenericPageElements/GenericDataChart";
import GenericPageContainer from "../../../GenericPageElements/GenericPageContainer";
import GenericProductFilterContainer from "../../../GenericPageElements/GenericProductFilterContainer";
import endpoints from "../../../helpers/endpoints";
import hasPermission from '../../../helpers/permissions';
import request from "../../../helpers/request";
import S3Modal from "../../../components/s3Modal/S3Modal";
import session from "../../../stores/session";
import GenericDataRow from "../../../GenericPageElements/GenericDataRow";
import { view } from "@risingstack/react-easy-state";
import { Link } from "react-router-dom";
import BulkBuy from '../../../components/cot-alert-bulk-buy/bulkBuy';
import BulkAllocate from '../../../components/cot-alert-bulk-allocate/bulkAllocate';
import { CustomizedAxisTickMultiLine } from "../CompaniesHouseAlerts/CompaniesHouseAlerts";
import './ManagedService.scss';

export default view(function ManagedServiceSummary() {
  const [filters, setFilters] = useState({});
  const [selectedGroupBy, setSelectectGroupBy] = useState("Assigned_To");
  const [data, setData] = useState(null);
  const [dates, setDates] = useState([]);
  const [date, setDate] = useState(null);
  const [S3Model, setS3Model] = useState(null);
  const [bulkModel, setBulkModel] = useState('')

  useEffect(() => {
    console.log(S3Model)
  }, [S3Model])
  
  const applyFilter = (column, value) => {
    setFilters((current) => {
      return {
        ...current,
        [column]: value,
      };
    });
  };

  useEffect(() => {
    request(true)
      .get(endpoints.MANAGED_SERVICE_DATES)
      .then((e) => {
        setDates(e.data.dates);
        if (e.data.dates.length > 0) setDate("All_Refreshes");
      });
  }, []);

  useEffect(() => {
    // if (!date) return;
    request(true)
      .get(endpoints.MANAGED_SERVICE_DATA, {
        doesCancel: true,
        params: {
          date: date,
          filter: filters,
          group: selectedGroupBy,
        },
      })
      .then((e) => {
        setData(e.data);
      });
  }, [filters, selectedGroupBy, date]);

  return [
    <GenericPageContainer
      title="Managed Service - Summary"
      titleIconClass={"fak fa-id-business-icon fa-2x colour-primary"}
      titleRight={
        <div>
          {hasPermission('MANAGED_SERVICE', 'UPLOAD') ? (
            <button className="navigate" onClick={() => setS3Model("upload_spids")}>
              Upload SPIDs
            </button>
          ) : null}
          {hasPermission('MANAGED_SERVICE', 'UPLOAD') ? (
            <button className="navigate" onClick={() => setS3Model("upload_meters")}>
              Upload Meters
            </button>
          ) : null}
          { (hasPermission('MANAGED_SERVICE', 'WORKFLOW')) ?
            <Link className="navigate" to="/portal/managed-service/premises-workflow">
              Premises Workflow
            </Link>
          : null }
          { (hasPermission('MANAGED_SERVICE', 'WORKFLOW')) ?
            <Link className="navigate" to="/portal/managed-service/meters-workflow">
              Meters Workflow
            </Link>
          : null }
        </div>
      }
    >
      <div style={{maxWidth: '20vw', marginBottom: 15}}>
      <GenericDataRow title={"File Date"} contentColour={"white"}>
        <select
          onChange={(_) => setDate(_.target.value)}
          value={date}
          style={{
            width: "100%",
            height: "100%",
            border: "none",
            backgroundColor: "white",
            paddingLeft: "10px",
          }}
        >
          <option key={"All_Refreshes"} value={"All_Refreshes"}>All Refreshes</option>
          {dates.map((item) => {
            return (
              <option key={item.raw} value={item.raw}>
                {item.pretty}
              </option>
            );
          })}
        </select>
      </GenericDataRow>
      </div>
      <div className='MS'>
        <GenericDataChart
          dataReady={!!data}
          data={data}
          selectedGroupBy={selectedGroupBy}
          setSelectectGroupBy={setSelectectGroupBy}
          groupingsEndpoint={endpoints.MANAGED_SERVICE_GROUPINGS}
          sizes={{chart: 600, chartContainer: 625, chartXAxisFontSize: 12, wrapperStyle: { paddingTop: '40px' }}}
          xAxisProps={{interval: 0}}
          customBarTick={CustomizedAxisTickMultiLine}
          overrideTableHeader={"Summary"}
          displayModeInitial="table"
        />
        <GenericProductFilterContainer clearFilters={() => setFilters({})}>
          <ProductDataFilter
            filterState={filters}
            applyFilter={applyFilter}
            dataFilterEndpoint={endpoints.MANAGED_SERVICE_FILTERS}
          />
          <div className='grid grid-columns-2 grid-gap-5 mta'>
              { (hasPermission('MANAGED_SERVICE', 'ALLOCATE')) ?
                <button className="button bulk-buy-button compact smaller-text" onClick={() => setBulkModel('allocate_premises')}>
                    Allocate Premises
                </button>
              : '' }
              { (hasPermission('MANAGED_SERVICE', 'ALLOCATE')) ?
                <button className="button bulk-buy-button compact smaller-text" onClick={() => setBulkModel('allocate_meters')}>
                    Allocate Meters
                </button>
              : '' }
              { (hasPermission('MANAGED_SERVICE', 'UNALLOCATE')) ?
                <button className="button bulk-buy-button compact smaller-text" onClick={() => setBulkModel('unallocate_premises')}>
                    Unallocate Premises
                </button>
              : '' }
              { (hasPermission('MANAGED_SERVICE', 'UNALLOCATE')) ?
                <button className="button bulk-buy-button compact smaller-text" onClick={() => setBulkModel('unallocate_meters')}>
                    Unallocate Meters
                </button>
              : '' }
          </div>
        </GenericProductFilterContainer>
      </div>
    </GenericPageContainer>,
    <S3Modal
      closeModal={() => setS3Model(null)}
      modalMode={S3Model}
      showModal={S3Model === "upload_spids"}
      titleOverride={"Upload Core SPID list"}
      listingUrl={endpoints.MANAGED_SERVICE_FILES}
      uploadUrl={endpoints.MANAGED_SERVICE_UPLOAD_SPIDS}
      successMessageOverride={"Your file is being processed. You will receive an email once complete."}
    />,
    <S3Modal
      closeModal={() => setS3Model(null)}
      modalMode={S3Model}
      titleOverride={"Upload Meters"}
      showModal={S3Model === "upload_meters"}
      listingUrl={endpoints.MANAGED_SERVICE_FILES}
      uploadUrl={endpoints.MANAGED_SERVICE_UPLOAD_METERS}
      successMessageOverride={"Your file is being processed. You will receive an email once complete."}
    />,
    <BulkAllocate 
    workflowUsersEndpoint={endpoints.MANAGED_SERVICE_WF_USERS + '?type=premises'} 
    bulkEndpoint={endpoints.MANAGED_SERVICE_BULK_SPIDS} 
    unallocateEndpoint={endpoints.MANAGED_SERVICE_UNALLOCATE_SPIDS} 
    modalIsOpen={bulkModel === 'allocate_premises' || bulkModel === "unallocate_premises"} 
    mode={bulkModel === "unallocate_premises" ? "unallocate" : "allocate_premises"}
    closeModal={() => setBulkModel('')} 
    filters={filters} 
    date={date} />,
    <BulkAllocate 
    workflowUsersEndpoint={endpoints.MANAGED_SERVICE_WF_USERS + '?type=meters'} 
    bulkEndpoint={endpoints.MANAGED_SERVICE_BULK_METERS} 
    urlParams={{test: "yes"}} 
    unallocateEndpoint={endpoints.MANAGED_SERVICE_UNALLOCATE_METERS} 
    modalIsOpen={bulkModel === 'allocate_meters' || bulkModel === "unallocate_meters"} 
    mode={bulkModel === "unallocate_meters" ? "unallocate" : "allocate_meters"}
    closeModal={() => setBulkModel('')} 
    filters={filters} 
    date={date} />,
  ];
})
